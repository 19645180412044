import Alpine from 'alpinejs'
import MicroModal from 'micromodal';  // es6 module

function init() {

  window.Alpine = Alpine;
  Alpine.start();

  document.addEventListener("touchstart", function() {}, true);

  MicroModal.init();

  $('a[href^="#"], button[href^="#"]').on('click', function(e) {
    e.preventDefault();

    const scrollTop = $($(this).attr('href')).position().top - 100;

    $('html, body').animate({ scrollTop });
  })

}


window.addEventListener("load", function () {
  init(); //
  // window.Fancybox = Fancybox;

});
